import React, { useEffect, useRef, useState } from "react";
import {
  AboutLeft,
  AboutPuzzle,
  CartUpper,
  CartDown,
  HomeHeader,
  Cartton,
  random1,
  random3,
} from "../../assets";
import PuzzleCenter from './../../assets/puzzle_logo.png'
import MainLayout from "../../layouts/MainLayout";
import { FaAngleUp, FaAngleDown } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { doPost } from "./../../utils/apiCalls";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { IoMdCloseCircle } from "react-icons/io";

const Home = () => {
  const navigate = useNavigate();
  const [CartCount, setCartCount] = React.useState(1);
  const aboutRef = useRef(null);
  const productRef = useRef(null);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [popup, setPopup] = useState(false);
  const [price, setPrice] = useState(0)
  const [user, setUser] = useState(1)
  const style = { "layout": "vertical" };
  const [productdata, setProductData] = useState('')

  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf('/');
  const slugFromUrl = lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : '';
  // console.log(slugFromUrl, "SLUG")
  useEffect(() => {
    if (location.state?.scrollTo) {
      const sectionRef =
        location.state.scrollTo === "about" ? aboutRef : productRef;

      const scrollToSection = () => {
        if (sectionRef.current) {
          sectionRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
          console.error("Element not found:", location.state.scrollTo);
        }
      };

      const timer = setTimeout(scrollToSection, 100);
      return () => clearTimeout(timer);
    }
  }, [location.state]);

  useEffect(() => {
    if(slugFromUrl == "retailers"){
      localStorage.setItem("usertype", "2");
      setUser(2)
    } else {
      localStorage.setItem("usertype", "1");
      setUser(1)
    }
    var user_type = localStorage.getItem("usertype");
    if (user_type == null) {
      setUser(1)
    } else {
      setUser(user_type)
    }
    get_products();
  }, []);

  const get_products = async () => {
    setLoading(true);
    const payload = {
      token: 1
    }
    const { isError, data } = await doPost(payload, 'auth/get_all_products');
    if (isError) {
      toast.error('Something went wrong with server.');
      setLoading(false);
    } else {
      if (data?.action == "success") {
        setProducts(data?.message)
        setLoading(false);
      }
      else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  }
  const do_validate_paypaldata = () => {
    if (!address || !city || !state || !country || !name || !email) {
      toast.error("please fill all billing details");
      return;
    }
    if (validateEmail(email)) {
    } else {
      toast.error("Please enter valid email address.")
      return;
    }

    setSuccess(true)

  }
  // creates a paypal order
  const createOrder = (data, actions) => {
    var total_paypal = ((user == 2 ? productdata?.price_retailer : productdata?.price) * CartCount).toFixed(2)
    return actions.order.create({
      purchase_units: [
        {
          description: productdata?.title,
          amount: {
            currency_code: "USD",
            value: total_paypal,
          },
        },
      ],
    }).then((orderID) => {
      setOrderID(orderID);
      setLoading(false)
      return orderID;
    });
    // setLoading(true)

  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setPopup(false)
      send_paypal_order()
    });
  };

  const send_paypal_order = async () => {
    setLoading(true)
    const payload = {
      id: productdata?.id,
      qty: CartCount,
      name: productdata.title,
      price: user == 2 ? productdata?.price_retailer : productdata?.price,
      total: ((user == 2 ? productdata?.price_retailer : productdata?.price) * CartCount).toFixed(2),
      address: address,
      city: city,
      state: state,
      country: country,
      name: name,
      email: email
    }
    const { isError, data } = await doPost(payload, 'auth/do_success_order');
    if (isError) {
      toast.error('Something went wrong with server.');
      setLoading(false);
    } else {
      if (data?.action == "success") {
        toast.success("Your order has been placed succcfully!");
        setLoading(false);
        setPopup(false)
      }
      else {
        setPopup(false)
        setLoading(false);
        toast.error(data?.error);
      }
    }
  }

  //capture likely error
  const onError = (data, actions) => {
    alert("An Error occured with your payment ");
  };

  const handleSubmit = async (event) => {
    if (!address || !city || !state || !country || !name || !email) {
      toast.error("please fill all billing details");
      return;
    }
    if (validateEmail(email)) {
    } else {
      toast.error("Please enter valid email address.")
      return
    }
    setLoading(true)
    const payload = {
      id: productdata?.id,
      qty: CartCount,
      name: productdata.title,
      price: user == 2 ? productdata?.price_retailer : productdata?.price,
      total: ((user == 2 ? productdata?.price_retailer : productdata?.price) * CartCount).toFixed(2),
      address: address,
      city: city,
      state: state,
      country: country,
      name: name,
      email: email
    }
    const { isError, data } = await doPost(payload, 'auth/stripecheckout');
    if (isError) {
      toast.error('Something went wrong with server.');
      setLoading(false);
    } else {
      if (data?.action == "success") {
        localStorage.setItem('PuzzleOrder', JSON.stringify(payload));
        window.location.href = data.message;
        setLoading(false);
      }
      else {
        setLoading(false);
        toast.error(data?.error);
      }
    }

  }

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      do_store_final_order()
    }
    if (query.get("canceled")) {
      localStorage.removeItem('PuzzleOrder');
      toast.success("Your payment has been cancelled, Please try again!");
    }
  }, []);

  const do_store_final_order = async () => {
    setLoading(true)
    var data_send = localStorage.getItem('PuzzleOrder');
    var object_Data = JSON.parse(data_send)
    const { isError, data } = await doPost(object_Data, 'auth/do_success_order');
    if (isError) {
      toast.error('Something went wrong with server.');
      setLoading(false);
    } else {
      if (data?.action == "success") {
        // localStorage.removeItem('OrderData');
        toast.success("Your Order has been placed successfully!");
        setLoading(false);
        // setTimeout(() => {
        //   window.location.href = data.message;
        // }, 1500);
      }
      else {
        // localStorage.removeItem('OrderData');
        setLoading(false);
        toast.error(data?.error);
      }
    }
  }


  return (
    <MainLayout>
      {/* /// header start  */}
      <div className=" w-full  overflow-hidden relative">
        <img
          src={HomeHeader}
          className="w-full h-auto object-cover"
          alt="HomeHeader.svg"
        />
        <div className="h-full w-full absolute top-0 left-0">
          <h1 className="flex justify-center MainHead text-secondary relative md:top-36 top-16 text-center text-4xl md:text-7xl lg:text-[135px]">
            {/* PUZZLE BUBS */}
            <img src={PuzzleCenter} className="image_bubble h-auto w-[350px]" />
          </h1>
        </div>
      </div>
      {/* /// header end  */}

      {/* /// About us start */}

      <div
        ref={aboutRef}
        id="about"
        className="sm:w-[80%] w-[90%] m-auto pt-10 "
      >
        <div className="relative p-3 w-max m-auto flex justify-center">
          <img
            src={AboutLeft}
            className="absolute -left-6 -top-6"
            alt="AboutLeft.svg"
          />
          <img
            src={AboutPuzzle}
            className="absolute -top-10"
            alt="AboutPuzzle.svg"
          />
          <h1 className=" text-secondary AboutHead text-center lg:text-7xl text-5xl font-magical leading-[72px] tracking-[0.144px] font-normal">
            About
          </h1>
        </div>
        <p className="text-center font-quicksand text-lg font-semibold leading-7 tracking-[-0.54px] text-[#777980] py-4">
          Puzzle Bubs are a world of cuteness waiting to be explored. They're a
          line of cube-shaped, foam-filled, mix-and-matchable jigsaw plushies,
          letting you piece together your special companions, Mix and match
          their heads and bodies to create unique cuddly Bubs, each with its own
          special scent, playful crinkle, and collectible Norsom hang tag. All
          Puzzle Bubs are perfectly filled with fluffy foam, ensuring no Bub is
          ever under- or over-stuffed. Start your mix-and-matchable, absolutely
          adorable Puzzle Bubs collection.
        </p>
        {/* <button className="text-[#1D1F2C] font-quicksand py-[10px] px-6 bg-secondary rounded-[10px] block m-auto transition-all duration-300 hover:bg-secondary-dark hover:text-white hover:shadow-lg">
          Explore More
        </button> */}
      </div>

      {/* /// About us end */}

      {/* /// Cart Box Start */}
      {
        loading ?
          <>
            <div
              ref={productRef}
              id="product"
              className="w-full relative bg-[#FFF23E]"
            >
              <img
                className="h-auto absolute bottom-0 left-0 w-full object-cover"
                src={CartUpper}
                alt="cardback.svg"
              />
              <div className="lg:w-[85%] w-[90%] lg:py-32 md:pt-10 md:pb-32 py-10  grid lg:grid-cols-1 grid-cols-1 m-auto relative z-50">
                <div className="lg:py-5 flex justify-center items-center" style={{ height: '300px' }}>
                  Loading....
                </div>
              </div>
              <img src={random1} className="absolute bottom-60 right-96" alt="" />
              <img
                src={random3}
                className="absolute bottom-72 right-[500px]"
                alt=""
              />
              <img
                className="h-auto absolute top-0 left-0 w-full object-cover"
                src={CartDown}
                alt="cardback.svg"
              />
            </div>
          </>

          :
          <>
            {
              products.map((item, index) => (
                <div
                  key={"pro_" + index}
                  ref={productRef}
                  id="product"
                  className="w-full relative bg-[#FFF23E]"
                >
                  <img
                    className="h-auto absolute bottom-0 left-0 w-full object-cover"
                    src={CartUpper}
                    alt="cardback.svg"
                  />
                  <div className="lg:w-[85%] w-[90%] lg:py-32 md:pt-10 md:pb-32 py-10  grid lg:grid-cols-2 grid-cols-1 m-auto relative z-50">
                    <div className=" lg:py-5">
                      <img className="h-full" src={item.image} alt={item.title} />
                    </div>
                    <div className="lg:py-2 relative">
                      <div className="lg:pt-10 lg:pb-0 pb-5 flex flex-col gap-[24px] ">
                        <h1 className="cartHeading relative text-primary sm:font-normal font-extralight font-magical lg:text-[76px] sm:text-6xl text-5xl m-0">
                          {item.title}
                        </h1>
                        <p className="text-[#1D1F2C] font-quicksand font-bold text-lg leading-9 tracking-[-0.63px]">
                          {item.short_desc}
                        </p>
                        {/* <div className="font-quicksand">
                  <p className="sm:text-lg text-base text-[#1D1F2C] font-medium leading-8">
                    <span className="font-bold">Size:</span>6in X 6in X 12in
                  </p>
                  <p className="sm:text-lg text-base text-[#1D1F2C] font-medium leading-8">
                    <span className="font-bold">Made With:</span> Polyester
                    fabric. Memory foam (polyurethane) stuffing, vanilla smell,
                    crinkle nose and utter.
                  </p>
                  <p className="sm:text-lg text-base text-[#1D1F2C] font-medium leading-8">
                    <span className="font-bold">Includes:</span>Norsom hangtag
                    puzzle poem story.
                  </p>
                </div> */}
                        <h1 className="text-[#EA3932] font-quicksand sm:text-4xl text-3xl font-bold leading-9">
                          <span>$</span>{user == 1 ? item?.price : item?.price_retailer}
                        </h1>
                        <div className="py-4 flex gap-4 font-quicksand">
                          <div className="px-6 pr-10 relative text-2xl border-4 rounded-[10px] text-white font-bold py-3 border-[#1D1F2C] w-max">
                            <span className="text-[#1D1F2C]">{CartCount}</span>
                            <div className="absolute top-1 right-1 bg-[#ffffff4a] border border-[#eeeeee4a]">
                            <FaAngleUp
  onClick={() => {
    let qty_follow;

    if (slugFromUrl == "retailers") {
      qty_follow = parseInt(item?.qty_retailer, 10) || 0;
      if (CartCount < qty_follow) {
        // Set CartCount directly to qty_retailer instead of adding
        setCartCount(qty_follow);
        toast.error("Minimum qty should be " + qty_follow + " units");
        setLoading(false);
        return;
      }
    } else {
      qty_follow = parseInt(item?.qty, 10) || 0;
      if (CartCount < qty_follow) {
        // Set CartCount directly to qty
        setCartCount(qty_follow);
        toast.error("Minimum qty should be " + qty_follow + " units");
        setLoading(false);
        return;
      }
    }

    // If CartCount is already equal or greater, simply add qty_follow
    setCartCount(prevCartCount => prevCartCount + qty_follow); // Ensure correct addition
  }}
  className="border border-[#eeeeee4a] text-[#1D1F2C] cursor-pointer"
/>



                              {/* <FaAngleDown
                                onClick={() => setCartCount(CartCount - 1)}
                                className="border border-[#eeeeee4a] text-[#1D1F2C] cursor-pointer"
                              /> */}

<FaAngleDown
  onClick={() => {
    let qty_follow;

    if (slugFromUrl == "retailers") {
      qty_follow = Number(item?.qty_retailer) || 0; // Ensure qty_follow is a number
      if (CartCount - qty_follow < 0) {
        toast.error("Quantity cannot be less than 0");
        setLoading(false);
        return;
      }
    } else {
      qty_follow = Number(item?.qty) || 0; // Ensure qty_follow is a number
      if (CartCount - qty_follow < 0) {
        toast.error("Quantity cannot be less than 0");
        setLoading(false);
        return;
      }
    }

    setCartCount(prevCartCount => Math.max(Number(prevCartCount) - qty_follow, 0)); // Subtract qty_follow and ensure it doesn't go below 0
  }}
  className="border border-[#eeeeee4a] text-[#1D1F2C] cursor-pointer"
/>
                            </div>
                          </div>
                          <button className="py-4 px-8 bg-primary w-full text-white rounded-[10px] text-[22px] font-bold leading-[22px] tracking-[-0.66px] transition-all duration-300 hover:bg-primary-dark hover:scale-105 hover:shadow-lg" onClick={() => {
                            if (user == 2) {
                              var qty_follow = item?.qty_retailer;
                              if (qty_follow > CartCount) {
                                setCartCount(item?.qty_retailer)
                                toast.error("Minimum qty should be " + qty_follow + " units");
                                setLoading(false)
                                return
                              }
                            }
                            setPopup(true)
                            setProductData(item)
                          }
                          }>
                            Buy
                          </button>
                        </div>
                        <h1 className="text-[#1D1F2C] font-quicksand sm:text-[22px] text-base font-bold leading-[22px] tracking-[-0.66px]">
                          *FREE USA GROUND SHIPPING AVAILABLE
                        </h1>
                      </div>
                    </div>
                    <div className="w-full lg:px-10 pb-4 relative font-quicksand">
                      <h1 className="text-primary text-[26px] font-bold leading-6 tracking-[-0.78px] border-b border-primary py-1 w-max ">
                        Details
                      </h1>
                      <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                      {/* <ul className="px-5 pt-3">
                <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                  Size: 12in
                </li>
                <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                  Stuffed with fluffy foam
                </li>
                <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                  Surface clean
                </li>
                <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                  Norsom hangtag with collectable artwork
                </li>
                <li className="text-xl text-[#1D1F2C] font-semibold leading-8 list-disc">
                  Free shipping
                </li>
              </ul> */}
                    </div>
                  </div>
                  <img src={random1} className="absolute bottom-60 right-96" alt="" />
                  <img
                    src={random3}
                    className="absolute bottom-72 right-[500px]"
                    alt=""
                  />
                  <img
                    className="h-auto absolute top-0 left-0 w-full object-cover"
                    src={CartDown}
                    alt="cardback.svg"
                  />
                </div>
              ))

            }
          </>

      }


      {
        popup &&
        <div className=" top-0 left-0 right-0 bottom-0 " style={{ background: "rgba(0, 0, 0, 0.8)", zIndex: 999, position: "fixed" }}>
          <div className="flex items-center justify-center" style={{ height: "100vh" }}>
            <div className=" bg-white p-10 white_border_center relative white_max_height" style={{ borderRadius: "20px" }}>
              <div className="absolute" style={{ top: '10px', right: '10px', cursor: "pointer" }} onClick={() => setPopup(false)}>
                <IoMdCloseCircle size={24} />
              </div>
              <div className="text-center" style={{ marginBottom: "20px" }}>
                <h1 style={{ color: "#202020", fontWeight: "bold", marginBottom: "20px", textTransform: "uppercase" }}>Choose Payment Method</h1>

                <div className="flex items-center justify-between mb-2 price_table">
                  <span className="font-bold text-xs">Unite Price</span>
                  <span className="text-sm">${user == 2 ? productdata?.price_retailer : productdata?.price} x {CartCount}</span>
                </div>
                {/* <div className="flex items-center justify-between mb-2 price_table">
              <span className="font-bold text-xs">Qty</span>
              <span className="text-sm">{CartCount}</span>
            </div> */}
                <div className="flex items-center justify-between mb-4 ">
                  <span className="font-bold text-xs">Total Price</span>
                  <span className="text-sm">${(CartCount * (user == 2 ? productdata?.price_retailer : productdata?.price)).toFixed(2)}</span>
                </div>

                <div className="flex justify-center">
                  <h1 style={{ color: "#202020", fontWeight: "bold", marginBottom: "20px", textTransform: "uppercase" }}>Billing Details</h1>


                </div>

                <div className="flex flex-col gap-3 mb-4">
                  <input type="text" name="address" placeholder="Address" className="billing_form" value={address} onChange={(e) => setAddress(e.target.value)} />
                  <div className="flex justify-between gap-4">
                     <input type="text" name="city" placeholder="City" className="billing_form w-full" value={city} onChange={(e) => setCity(e.target.value)} />
                     <input type="text" name="state" placeholder="State" className="billing_form w-full" value={state} onChange={(e) => setState(e.target.value)} />
                  </div>
                  <input type="text" name="country" placeholder="Country" className="billing_form" value={country} onChange={(e) => setCountry(e.target.value)} />
                  {/* <div className="flex justify-between gap-4"> */}
                    <input type="text" name="full_name" placeholder="Full Name" className="billing_form w-full" value={name} onChange={(e) => setName(e.target.value)} />
                    <input type="email" name="email" placeholder="Email Address" className="billing_form w-full" value={email} onChange={(e) => setEmail(e.target.value)} />
                  {/* </div> */}
                  {
                    !success &&

                    <button disabled={loading} className="stripe_button" onClick={() => do_validate_paypaldata()}>
                      {loading ? "Loading..." : "GO TO PAYMENT"}</button>
                  }
                </div>
                {
                  success &&
                  <>
                    <button disabled={loading} className="stripe_button" onClick={handleSubmit}>
                      {loading ? "Loading..." : "Pay With Stripe"}</button>
                    <p style={{ textAlign: "center", fontSize: "14px", marginTop: "10px", marginBottom: "10px" }}>OR PAY WITH</p>
                    <PayPalScriptProvider options={{ "client-id": 'Ab1NoNLMHepKfnG2QI4QasSP-mlTZBVTwFoujmeasRoL7OsLbYx5ViWGw_jCKPu584mxR-dABbVB0moj' }}>
                      <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                      />
                    </PayPalScriptProvider>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      }


      {/* /// Cart Box end */}
      {/* <div className="md:w-1/2 sm:w-3/4 w-[90%] m-auto py-16">
        <h1 className=" text-secondary AboutHead text-center lg:text-6xl text-5xl font-magical leading-[80px] tracking-[0.144px] font-normal">
          Please feel free to call or email rain or shine, day or night
        </h1>
      </div> */}
    </MainLayout>
  );
};

export default Home;
